import React, { forwardRef, PropsWithoutRef } from "react"
import { useField } from "react-final-form"
import { Form, Input, InputProps } from "antd"
export interface LabeledTextFieldProps extends PropsWithoutRef<InputProps> {
  /** Field name. */
  name: string
  /** Field label. */
  label: string
  /** Field type. Doesn't include numbers, radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "tel"
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>
}

export const LabeledTextField = forwardRef<HTMLInputElement, LabeledTextFieldProps>(
  ({ name, label, outerProps, ...props }, ref) => {
    const {
      input,
      meta: { touched, error, submitError, submitting },
    } = useField(name)

    const normalizedError = Array.isArray(error) ? error.join(", ") : error || submitError

    return (
      <div {...outerProps}>
        <Form.Item
          style={{ display: "flex", width: "100%" }}
          label={label}
          help={
            touched &&
            normalizedError && (
              <div role="alert" style={{ color: "red" }}>
                {normalizedError}
              </div>
            )
          }
        >
          {props.type === "password" ? (
            <Input.Password {...input} disabled={submitting} {...props} />
          ) : (
            <Input {...input} disabled={submitting} {...props} />
          )}
        </Form.Item>
      </div>
    )
  }
)

export default LabeledTextField
